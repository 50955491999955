<template>
  <div
    v-if="client"
    class="tw-flex tw-items-center like-h5"
  >
    <span>
      {{ clientName }}
    </span>
    <div v-if="client.notes_count">
      <el-tooltip
        placement="top"
      >
        <div class="tw-flex tw-items-center">
          <icon-notes class="tw-text-muted tw-w-3.5 tw-h-4 tw-ml-2" />
        </div>
        <template #content>
          Poznámka
        </template>
      </el-tooltip>
    </div>
    <RequestWarningBadge
      :client="client"
      :request="request"
      class="tw-ml-2"
    />
    <OnlineStatus
      class="tw-ml-2"
      :is-online="client.is_online"
    />
  </div>
</template>

<script>
export default {
  props: {
    client: {
      type: Object,
      required: true,
    },
    request: {
      type: Object,
      default: null,
    },
  },

  computed: {
    clientName() {
      if (this.client.name) {
        return this.client.name;
      }

      if (this.client.first_name && this.client.last_name) {
        return `${this.client.first_name} ${this.client.last_name}`;
      }

      return 'Dočasný uživatel';
    },
  },
};
</script>
